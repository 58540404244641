<template>
  <div class="elite-tabs-wrapper-content">
    <div v-for="event in auctionEvents" :key="event.id">
      <h4>{{ event.name }}</h4>
      <p> <label>{{ $t("EVENTS.DATE_START") }}</label> : {{ $formatDate(event.start_at) }}</p>
      <p> <label>{{ $t("EVENTS.DATE_END") }}</label> : {{ $formatDate(event.end_at) }}</p>

    </div>
  </div>
</template>

<script>

export default {
  name: "auction-events-list",

  props: ["auction"],

  components: {
  },

  data() {
    return {
      auctionEvents: [],
      total: 0,
      pagination: {
        perPage: 500,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      loading: true,
      query: null,
    };
  },

  computed: {},

  created() { },

  methods: {
    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
            ...(this.auction.id ? { auction_id: this.auction.id } : {})
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };

        await this.$store.dispatch("events/list", params);
        this.auctionEvents = this.$store.getters["events/list"];
        this.auctionEvents = this.auctionEvents.filter(event => (event.parent_id || event.recurrent == 0));
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {
    this.getList();
  },

  watch: {
    auction(auction) { },
  },
};
</script>