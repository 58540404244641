<template>
  <div>
    <div style="display: inline">
      <badge :type="getDeliveryBadgeType()">
        {{ $t(`VEHICLES.VEHICLES_STATUS_${property.status}`) }}
      </badge>
    </div>
  </div>
</template>

<script>
import {
  STATUS_ACTIVE,
  STATUS_CANCEL,
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_CONFIRMED,
} from "@/constants/vehicles";

export default {
  name: "vehicle-status-badge-component",

  components: {},

  mixins: [],

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    getDeliveryBadgeType() {
      switch (this.property.status) {
        case STATUS_ACTIVE:
          return "success";
        case STATUS_PENDING:
          return "primary";
        case STATUS_APPROVED:
          return "warning";
        case STATUS_CANCEL:
          return "danger";
        case STATUS_CONFIRMED:
          return "yellow";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
