<template>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="auctionOtherFees"
          >
            <el-table-column :label="$t('AUCTIONS.FEES_NAME')" min-width="30">
              <template v-slot="{ row }">
                {{ row?.name }}
              </template>
            </el-table-column>
  
            <el-table-column :label="$t('AUCTIONS.FEES_TYPE')" min-width="30">
              <template v-slot="{ row }">
                {{ $t(`AUCTIONS.FEES_TYPE_${row?.fees_type}`) }}
              </template>
            </el-table-column>
  
            <el-table-column :label="$t('AUCTIONS.AMOUNT')" min-width="30">
              <template v-slot="{ row }">
                <span v-if="row.amount">
                  {{ $formatCurrency(row.amount) }}
                </span>
                <span v-else>
                  -
                </span>
              </template>
            </el-table-column>
  
            <el-table-column :label="$t('AUCTIONS.PERCENT')" min-width="30">
              <template v-slot="{ row }">
                <span v-if="row.percent">
                  {{ row.percent }}%
                </span>
                <span v-else>
                  -
                </span>
  
              </template>
            </el-table-column>
  
            <el-table-column min-width="10">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                    :content="$t('COMMON.EDIT')" placement="top"
                    v-if="$currentUserCan($permissions.PERM_EDIT_OTHER_FEES)"
                >
                  <a
                      type="button"
                      :disabled="submitting"
                      @click="onEditFee(row)"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
  
                <el-tooltip
                    :content="$t('COMMON.DELETE')" placement="top"
                    v-if="$currentUserCan($permissions.PERM_DELETE_OTHER_FEES)"
                >
                  <a
                      type="text"
                      :disabled="submitting"
                      @click="onDeleteFee(row)"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
  
      <auction-other-fee-form
          :auction="auction"
          :showModal="showModal"
          @onToggleAuctionFeeModal="onToggleAuctionFeeModal"
          :loading="loading"
          :formData="tempFees"
          :reloadData="getList"
      />
    </div>
  </template>
  
  <script>
  import {Button, Table, TableColumn, Tooltip} from "element-ui";
  import formMixin from "@/mixins/form-mixin";
  import AuctionOtherFeeForm from "./AuctionOtherFeeForm"
  import defaultAuctionOtherFee from "../defaultAuctionOtherFee";
  import requestErrorMixin from "@/mixins/request-error-mixin";
  import swal from "sweetalert2";
  
  export default {
    name: "auction-other-fee-list-table",
  
    components: {
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      AuctionOtherFeeForm,
    },
  
    mixins: [formMixin, requestErrorMixin],
  
    props: {
      filterAuctionId: {
        type: String,
        default: null,
        description: "Auction id",
      },
      showModal: {
        type: Boolean,
        default: false,
        description: "Modal State",
      },
      auction: {
        type: Object,
        default: null,
        description: "Auction",
      },
    },
  
    data() {
      return {
        auctionOtherFees: [],
        loading: false,
        submitting: false,
        tempFees: defaultAuctionOtherFee,
      };
    },
  
    computed: {},
  
    methods: {
      onToggleAuctionFeeModal() {
        this.$emit("onToggleAuctionFeeModal");
      },
      onEditFee(fee) {
        if (this.submitting) {
          return
        }
        this.tempFees = fee;
        this.$emit("onToggleAuctionFeeModal");
      },
      onDeleteFee(fee) {
        if (this.submitting) {
          return
        }
        this.tempFees = fee;
        this.deleteTransactions()
      },
      getListDebounced: _.debounce(function () {
        this.getList();
      }, 300),
  
      async getList() {
        try {
          this.loading = true;
          let params = {
            ...(this.sort ? {sort: this.sort} : {}),
            filter: {
              ...(this.query ? {search: this.query} : {}),
            },
          };
  
          if (this.auction) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                auctionId: this.auction?.id,
              },
            };
          }
          await this.$store.dispatch("auctionOtherFees/list", params);
          this.auctionOtherFees = this.$store.getters["auctionOtherFees/list"];
          this.tempFees = defaultAuctionOtherFee
          this.loading = false;
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      },
  
      async deleteTransactions() {
        const confirmation = await swal.fire({
          title: this.$t("PROPERTIES.DELETE_TRANSACTION_ON_PROPERTY"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
  
        try {
          if (confirmation.value === true) {
            this.deleteAuctionOtherFee()
          }
        } catch (error) {
          await this.showRequestError(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      },
  
      async deleteAuctionOtherFee() {
        swal.fire({
          title: this.$t("COMMON.IN_PROCESSING"),
          onBeforeOpen: () => {
            swal.showLoading();
          },
        });
        this.submitting = true;
        try {
          await this.$store.dispatch("auctionOtherFees/destroy", this.tempFees?.id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("COMMON.ITEM_DELETED"),
          });
          this.submitting = false;
  
          swal.close();
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.submitting = false;
          swal.close();
        }
      },
    },
  
    mounted() {
      this.getList()
    },
  
    beforeDestroy() {
      this.$parent.$off("auctionOtherFeesUpdated", this.getListDebounced);
    },
  
    watch: {
      query: {
        handler: "getListDebounced",
        immediate: true,
      },
      showModal(value) {
        if (!value){
          this.tempFees = {
            ...defaultAuctionOtherFee,
          }
        }
      },
    },
  };
  </script>
  
  <!-- <style>
  .purchasesOrder-item-excerpt p,
  .purchasesOrder-item-excerpt,
  .purchasesOrder-item-excerpt * {
    font-size: 0.7rem;
  }
  </style> -->
  